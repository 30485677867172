@media screen and (max-width: 1800px) {

    .gallery__item--1,
    .gallery__wrapper--2 {
        width: 460px;
    }

    .gallery__item {
        max-width: 46%;
    }
}

@media screen and (max-width: 1600px) {
    .container {
        max-width: 100%;
    }

    .about__gallery {
        position: static;
    }

    .gallery__wrapper {
        max-width: 48%;
        height: auto;
    }

    .gallery__item {
        max-width: 48%;
        height: auto;
    }

    .gallery__item--1 {
        height: auto;
    }

    .order {
        background-position: -250px 0;
    }

    .projects__item {
        max-width: 32%;
    }

    .map {
        width: 700px;
    }

    .first {
        background-position: 135% -88px;
    }

    .gallery__wrapper--2 {

        &> :first-child,
        &> :nth-child(2) {
            margin-bottom: 13px;
        }
    }

    .company__img {
        min-width: auto;
        height: auto;
    }

    .projects__slider {
        .projects__item {
            max-width: 400px;
        }
    }

    .slick-next {
        right: 36px;
    }

    .slick-prev {
        right: 147px;
    }
}

@media screen and (max-width: 1420px) {
    .first {
        background-position: 300% -88px;
    }

    .slider__img {
        max-width: 700px;
        margin-right: -80px;
    }

    .news__name {
        font-size: 20px;
    }

    .product__content {
        max-width: 640px;
    }

    .product__tab {
        padding: 0 64.5px;
    }

    .product__wrapper2>:first-child {
        padding-right: 32px;
        margin-right: 32px;
    }

    .catalog {
        .sales__text {
            max-width: 536px;
        }
    }

    .catalog__menu {
        li {
            padding: 11px 66px;
        }
    }
}

@media screen and (max-width: 1350px) {
    .container {
        max-width: 1200px;
    }

    .first {
        background: 0;
    }

    .slider__img {
        max-width: 577px;
        margin-right: 0;
    }

    .order {
        background-position: -345px 0;
    }

    .info__wrapper--2 {
        margin-left: 0;
    }

    .info__box {
        justify-content: space-between;
    }


    .sales__box {
        padding-left: 15px;
    }

    .news__wrapper {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }

    .news__name,
    .news__text,
    .news__content {
        margin-left: 0;
    }

    .news__img,
    .news__button {
        margin: 0 auto;
    }

    .news__box {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .news__title {
        text-align: center;
    }

    .map {
        width: 590px;
    }

    .news__name {
        margin-top: 10px;
    }

    .company__box {
        .about__gallery {
            max-width: 630px;
            width: 100%;
        }
    }

    .certificates__item {
        max-width: 100%;
    }

    .order__box--2 {
        margin-left: auto;
    }

    .product__slider_item,
    .products__slider-box {
        width: 480px;
    }

    .technical__box {
        width: 100%;
    }

    .technical__row span {
        font-size: 18px;
    }

    .technikal__right {
        width: 498px;
    }

    .catalog {
        .sales__text {
            max-width: 500px;
        }
    }

    .catalog__menu {
        li {
            padding: 11px 46px;
        }
    }

    .internal {
        .products__item {
            max-width: 31.5%;
        }
    }

}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 990px;
    }

    .slider__img {
        max-width: 450px;
        margin-top: 59px;
        margin-left: -100%;
    }

    .services__item {
        max-width: none;
        margin-bottom: 20px;
    }

    .services__box {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .about__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .about__gallery {
        max-width: 100%;
        margin-top: 20px;
    }

    .about__text {
        max-width: none;
        margin-left: 0;
    }

    .order {
        background-position: -542px 0;
    }

    .merits__name {
        font-size: 22px;
        line-height: 26px;
    }

    .merits__text {
        br {
            display: none;
        }
    }

    .info__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .info__wrapper {
        width: 485px;
    }

    .info__button {
        margin-left: 0;
    }

    .info__wrapper--2 {
        margin-top: 40px;

        .info__button {
            margin-left: 0;
        }

    }

    .projects__item {
        max-width: 48%;
    }

    .sales__text {
        max-width: 500px;

        br {
            display: none;
        }
    }

    .sales__box {
        padding-bottom: 76px;
    }

    .map {
        position: static;
        width: 100%;
        margin-top: 20px;
    }

    .footer__nav {
        justify-content: center;
        margin-left: 0;
    }

    .footer__logo {
        display: block;
        text-align: center;
    }

    .footer {
        padding-bottom: 0;
    }

    .company__box--2 .company__text {
        margin-left: 0;
        margin-top: 20px;
    }

    .projects__slider .projects__item {
        max-width: 100%;
    }

    .projects__slider {
        margin-left: 0;
    }

    .merits--2 {
        padding-top: 50px;
    }

    .projects__box--2 {
        margin-top: 50px;
    }

    .footer__bottom-box,
    .footer__right {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .contacts__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .order__box--2 {
        margin-right: auto;
        margin-top: 40px;
    }

    .product_box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .product__content {
        margin-top: 30px;
    }

    .technikal__right {
        width: 440px;
    }

    .technical__row span {
        font-size: 15px;
    }

    .product__tab {
        padding: 0 20.5px;
    }

    .catalog {
        .sales__box {
            background-position-x: 540px
        }
    }

    .products__item {
        max-width: 32.5%;
    }

    .catalog__menu {
        li {
            padding: 11px 26px;
        }
    }

    .aside {
        width: 220px;
        min-width: 220px;
    }

    .accordion-item {
        font-size: 14px;
        padding-left: 10px;
    }

    .acc__link {
        padding-left: 15px;
        font-size: 14px;
    }

    .accordion-item.openable {
        background: url(../img/openable2.png) no-repeat 97.2% center;
    }

    .accordion-item.open.openable {
        background: #005193 url(../img/openable.png) no-repeat 97% center;
    }

    .service__main {
        margin-left: 25px;
    }

    .service__content {
        margin-left: 25px;
    }

    .internal {
        .products__item {
            max-width: 48%;
        }
    }

    .info__item {
        a {
            text-decoration: underline;

            &:after {
                content: '';
                display: none;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

}

@media screen and (max-width: 991px) {
    .container {
        max-width: 768px;
    }

    .logo img {
        max-width: 300px;
    }

    .slider__img {
        margin-left: -200px;
    }

    .slider__text {
        max-width: 350px;

        br {
            display: none;
        }
    }

    .slider__img {
        margin-left: -330px;
        margin-top: 80px;
    }


    .services__box {
        max-width: 556px;
        margin-left: auto;
        margin-right: auto;

        &> :last-child {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .order {
        background: transparent;
        padding: 0;
    }

    .order__box {
        margin-right: auto;
        margin-left: auto;
    }

    .merits__box {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .merits__item {
        max-width: 48%;
    }

    .projects__title {
        br {
            display: none;
        }
    }

    .projects__title {
        font-size: 25px;
        line-height: 35px;
    }

    .sales__box {
        background-position: 158% 0;
    }

    .sales__text {
        max-width: 448px;
    }

    .projects__text {
        font-size: 14px;
    }

    .certificates__slider .slick-slide img {
        margin: 0 auto;
    }

    .footer__right {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
        width: 100%;
    }

    .footer__text {
        text-align: center;
        width: 100%;
    }

    .footer__info {
        padding: 0;
        border: 0;
        margin-top: 20px;
    }

    .footer__mail {
        text-align: center;
    }

    .footer__contacts {
        margin-bottom: 0;
    }

    .footer__bottom-box {
        padding-top: 20px;
        text-align: center;
    }

    .footer__contacts,
    .footer__adress {
        margin-left: 0;
        margin-right: 0;
    }

    .ionTabs__tab_state_active {
        font-size: 20px;
    }

    .product__tab {
        font-size: 18px;
    }

    .product__link {
        padding-left: 20px;
    }

    .technical__row:after {
        content: '';
        display: none;
        margin: 0;
        padding: 0;
    }

    .technical__row span:first-of-type {
        float: none;
    }

    .technical__row span:last-of-type {
        float: none;
        margin: 0;
        padding: 0;
    }

    .technical__row span {
        display: block;
        font-size: 18px;
    }

    .technical__row {
        border-bottom: 1px solid #354668;
        padding: 20px 0;

        .technikal__right {
            width: 100%;
            color: #0247D6;
            font-weight: 500;
        }
    }

    .products__item {
        max-width: 49%;
    }

    .catalog__menu {
        li {
            padding: 11px 6px;
            font-size: 15px;
        }
    }

    .tidings__item {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
        padding: 15px;
    }

    .tidings__content {
        margin-left: 0;
        text-align: center;
    }

    .tidings__name {
        margin-top: 20px;
    }

    .service__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .aside {
        width: 100%;
    }

    .accordion-item {
        font-size: 16px;
        padding-left: 20px;
    }

    .acc__link {
        padding-left: 30px;
        font-size: 16px;
    }

    .accordion {
        margin-bottom: 40px;
    }

    .service__item {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: flex-start;
        align-items: flex-start;
    }

    .service__img {
        margin-bottom: 20px;
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 576px;
    }

    .about__gallery {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .gallery__wrapper {
        max-width: 100%;
        margin-top: 20px;
    }

    .cap__box {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .menu__label .menu {
        top: 20px;
    }

    .cap__right {
        width: 100%;
        margin-left: 64px;
        margin-top: 20px;
    }

    .main__title {
        font-size: 50px;
    }

    .first__button {
        margin-top: 182px;
    }

    .slider__img {
        margin-top: 216px;
        max-width: 250px;
        margin-left: 0;
    }

    .slider__text {
        max-width: 100%;
    }

    .slider .slick-arrow {
        top: 478px;
    }

    .merits__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .merits__item {
        max-width: 100%;
    }

    .info__title {
        font-size: 20px;
        line-height: 27px;
    }

    .projects__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .projects__item {
        max-width: 100%;
    }

    .sales__box {
        padding-right: 15px;
    }

    .sales__box {
        background-position: 410% 0;
    }

    .sales__title {
        font-size: 33px;
        line-height: 39px;
    }

    .sales__text {
        max-width: 337px;
    }

    .sales__box {
        padding: 63px 15px;
    }

    .sales__caption {
        font-size: 33px;
    }

    .sales__link {
        margin-top: 199px;
    }

    .news__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .news__item {
        margin-bottom: 40px;
    }

    .news__item {
        max-width: 100%;
    }

    .news__text {
        margin-bottom: 30px;
    }

    .cap__links_item {
        margin-right: 15px;
    }

    .cap__top_box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .cap__mail {
        margin-top: 10px;
    }

    .menu__label input:checked+.menu {

        border-radius: 0;
    }

    .contacts__wrapper {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;

        &> :first-child {
            margin-right: 0px;
        }
    }

    .contacts__block {
        margin: 0;
    }

    .requisites {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .requisites__send {
        margin-top: 30px;
        margin-left: 0;
    }

    .product__content {
        max-width: 100%;
    }

    .product__wrapper2 {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .product__wrapper2>:first-child {
        padding: 0;
        margin: 0;
        border: 0;
        margin-bottom: 10px;
    }

    .product__links,
    .product__wrapper {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .product__link--2 {
        margin: 20px 0 0 0;
    }

    .product__button {
        margin-left: auto;
        margin-right: auto;
    }

    .product__paragraph {
        text-align: center;
    }

    .product__tab {
        font-size: 17px;
        padding: 0 11px;
    }

    .ionTabs__tab_state_active {
        font-size: 18px;
    }

    .menu__label ul {
        z-index: 11;
    }

    .catalog .sales__box {
        background-image: none;
    }

    .catalog__menu {
        display: none;
    }

    .pagination {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 40px;

        li {
            margin-bottom: 15px;
        }
    }

    .pagination__item a {
        font-size: 18px;
        width: 40px;
        height: 44px;
    }


    .pagination__item {
        margin-right: 12px;
    }

    .pagination__button a {
        width: 100px;
        font-size: 16px;
    }

    .pagination__button {
        margin-right: 0;
    }

    .service__main {
        margin-left: 0;
    }

    .search__wrapper {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .search__paragraph,
    .search__news .search__paragraph,
    .search__sales .search__paragraph {
        margin-left: 0;
    }

    .search__type {
        top: 20px;
        right: 20px;
    }

    .search__img {
        margin-top: 20px;
    }
}

@media screen and (max-width: 576px) {
    .container {
        max-width: 100%;
    }

    .logo {
        img {
            max-width: 230px;
        }
    }

    .menu__label input:checked+.menu+ul {
        top: 275px;

        li {
            display: inline;
        }

        .menu__item a {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .phone {
        padding-left: 45px;
        padding-right: 5px;
        margin-right: 0;
        font-size: 22px;
    }

    .cap__right {
        margin-left: 0;
    }

    .cap__box {
        padding-right: 0;
    }

    .cap__links {
        display: none;
    }

    .cap__mail {
        margin-top: 0;
    }

    .menu__label .menu {
        top: 14px;
    }

    .main__title {
        font-size: 27px;
    }

    .slider__text {
        font-size: 16px;
        line-height: 23px;
        margin-top: 20px;
    }

    .slider__img {
        margin-top: 150px;
    }

    .first {
        padding: 40px 0;
    }

    .slider .slick-arrow {
        top: 415px;
    }

    .services__box {
        justify-content: center;
    }

    h2 {
        font-size: 21px;
    }

    .about {
        padding: 50px 0 100px 0;
    }

    .about__box {
        margin-top: 30px;
    }

    .order__title {
        font-size: 15px;
        line-height: 24px;
    }

    .order__title :first-child {
        font-size: 18px;
        line-height: 24px;
    }

    .order__title :last-child {
        font-size: 14px;
    }

    .about {
        padding-bottom: 60px;
    }

    .input {
        font-size: 14px;
        padding-left: 8px;
    }

    .button {
        max-width: 100%;
    }

    .merits {
        padding: 100px 0 60px 0;
    }

    .merits__name {
        font-size: 20px;
    }

    .merits__item {
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
        height: 380px;
    }

    .merits__name:after {
        content: '';
        margin: 21px auto 32px auto
    }

    .info__title {
        font-size: 18px;
    }

    .info__wrapper {
        width: 100%;
    }

    .info__item a {
        line-height: 20px;
        padding: 8px 0;
        display: block;
        font-size: 16px;

        br {
            display: none;
        }
    }

    .projects__title {
        font-size: 18px;
        line-height: 25px;

        br {
            display: none;
        }
    }

    .projects__content {
        padding-left: 0;
    }

    .projects__caption {
        font-size: 33px;
        line-height: 37px;
    }

    .projects__box {
        margin-top: 50px;
    }

    .projects__text {
        br {
            display: none;
        }
    }

    .projects__name {
        font-size: 18px;
        margin-top: 18px;
    }

    .projects__item {
        margin-bottom: 40px;
    }

    .projects {
        padding: 50px 0;
    }

    .sales__title {
        font-size: 22px;
        line-height: 27px;
    }

    .sales__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
        padding-bottom: 0;
    }

    .sales__right {
        background: #0045DB;
        width: 100%;
        padding: 20px 0 40px 0;
    }

    .sales__link {
        margin: 20px auto 0 auto;
    }

    .sales__caption {
        text-align: center;
        font-size: 20px;
    }

    .sales {
        padding-bottom: 0;
    }

    .sales__box {
        padding-top: 40px;
    }

    .news__title {
        font-size: 25px;
    }

    .news {
        padding: 60px 0;
    }

    .news__name {
        font-size: 18px;

        br {
            display: none;
        }
    }

    .news__text {
        font-size: 16px;

        br {
            display: none;
        }
    }

    .footer__nav {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }

    .footer__nav>:first-child {
        margin-right: 0px;
    }

    .footer__logo {
        margin-left: 0;

        img {
            max-width: 100%;
        }
    }

    .footer {
        padding-top: 40px;

        .menu__item a {
            font-size: 16px;
            line-height: 32px;
            text-align: center;
        }

        .menu__item {
            text-align: center;
        }
    }

    .slider__content {
        min-width: 100%;
    }

    .slider__text {
        max-width: 357px;
    }

    .sales__box {
        background: #f5f5f5;
    }

    .button {
        margin-left: auto;
        margin-right: auto;
    }

    .first__button {
        margin-left: 0;
    }

    .footer__nav {
        display: none;
        margin: 60px 0;
    }

    .footer__text {
        font-size: 12px;
        line-height: normal;
    }

    .requisites__input {
        width: 195px;
    }

    .requisites__button {
        width: 115px;
    }

    .order__box--2 .input {
        max-width: 100%;
    }

    .order__box--2 {
        max-width: 100%;
    }

    .requisites__text,
    .requisites__form-caption {
        text-align: center;
    }

    .product__slider_item,
    .products__slider-box {
        width: 100%;
    }

    .product__slider_item {
        height: auto;
        padding: 5px;
    }

    .cap-down__box {
        min-height: auto;
    }

    .product {
        margin-top: 30px;
    }

    .product__name {
        font-size: 20px;
    }

    .product__name:after {
        content: '';
        display: block;
        width: 78px;
        height: 4px;
        background: #0045db;
        margin: 10px auto 10px auto;
    }

    .product__paragraph {
        font-size: 16px;
    }

    .product__link {
        max-width: 100%;
    }

    .product__link {
        padding-left: 10px;
    }

    .product__link--2 {
        padding-top: 18px;
    }

    .ionTabs__head {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .ionTabs__tab {
        padding: 0;
        border: 0;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .tabs__head {
        border: 0;
    }

    .ionTabs__tab_state_active {
        border: 1px solid #b2b2b2;
    }

    .product__tabs,
    .tabs__body {
        margin-top: 40px;
    }

    .technical__row span {
        font-size: 16px;
        line-height: normal;
    }

    .technical__row span:last-of-type {
        margin-top: 10px;
    }

    .product__tabs {
        padding-bottom: 80px;
    }

    .order--2 {
        margin-bottom: 40px;
    }

    .catalog .sales__title {
        text-align: center;
    }

    .catalog .sales__box {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .catalog .sales__text {
        text-align: center;
    }

    .products__item {
        max-width: 100%;
    }

    .products {
        justify-content: center;
    }

    .catalog {
        padding: 47px 0 23px 0;

        .sales {
            margin-bottom: 70px;
        }
    }

    .catalog__title {
        font-size: 25px;
        text-align: center;
        margin-bottom: 51px;
    }

    .service__content {
        margin-left: 0;
        text-align: center;
        padding-left: 10px;
    }

    .service {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .search__item {
        padding: 20px 10px;
        padding-top: 40px;
    }

    .search__paragraph {
        text-align: center;
    }

    .search__title {
        font-size: 27px;
    }

    .search__box {
        padding: 40px 0;
        text-align: center;

    }

    .search__items {
        margin-top: 48px;
    }

    .internal {
        padding-bottom: 50px;

        .products__item {
            max-width: 100%;
        }

        .products {
            justify-content: center;
        }
    }

    .breadcrumbs {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .interlnal__title,
    .internal__text {
        text-align: center;
    }

    .interlnal__title {
        font-size: 27px;
    }

    .internal__more {
        text-align: left;
        display: inline-block;
    }

    .projects--page {
        .projects__main {
            padding-top: 0;
        }

        .projects__box {
            margin-top: 50px;
        }
    }


}